import LogOutButton from "../Buttons/logOutButton";
import "./custom.scss";
import { Footer } from "./Footer";

export const MasterLayout = ({ children }: any) => {
  return (
    <div className="min-h-screen">
      <header>
        <div className="flex flex-wrap border-gray-200 p-3 px-10 border-b-0 shadow-lg shadow-gray-300 bg-gray-50 font-semibold place-items-center">
          <div className="flex place-items-center pl-5">
            <img alt="Pattern" src="/images/logo.svg" width={30} />
            <div className="text-3xl   p-3  text-sky-500 cursor-default">
              WiFi Doors
            </div>
          </div>
          <div className="flex-grow"></div>
          <LogOutButton />
        </div>
      </header>
      <div className="container">{children}</div>
      <Footer />
    </div>
  );
};
