import { Outlet } from "react-router-dom";
import AuthCheck from "./Authcheck";
import { MasterLayout } from "./theme/MasterLayout";
import PopUpLayout from "./theme/PopUpLayout";

function App() {
  return (
    <AuthCheck>
      <MasterLayout>
        <PopUpLayout>
          <Outlet />
        </PopUpLayout>
      </MasterLayout>
    </AuthCheck>
  );
}

export default App;
