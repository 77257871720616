import { createBrowserRouter, RouterProvider } from "react-router-dom";
import App from "../App";
import Login from "../auth/login";
import Register from "../auth/register";
import List from "../doors/list";
import ShareDoor from "../doors/shareDoor";

export const Routers = () => {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <App />,
      children: [
        {
          path: "sharedoor",
          element: <ShareDoor />,
        },
        {
          path: "doors/list",
          element: <List />,
        },
      ],
    },
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/register",
      element: <Register />,
    },
    {
      path: "*",
      element: (
        <>
          HATA OLUŞTU...
          <br />
          veya
          <br /> SAYFA BULUNAMADI
        </>
      ),
    },
  ]);
  return <RouterProvider router={router} />;
};
