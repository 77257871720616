import { createSlice } from "@reduxjs/toolkit";

export interface ModalState {
  closeAllModals: boolean;
  logModal: number;
  shareModal: number;
  addDoorModal: boolean;
  editDoorModal: number;
}
const initialState: ModalState = {
  logModal: 0,
  shareModal: 0,
  addDoorModal: false,
  editDoorModal: 0,
  closeAllModals: true,
};

export const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    showLogModal: (state, action) => {
      state.logModal = action.payload;
    },
    hideLogModal: (state) => {
      state.logModal = 0;
    },
    showShareModal: (state, action) => {
      state.shareModal = action.payload;
    },
    hideShareModal: (state) => {
      state.shareModal = 0;
    },
    showAddDoorModal: (state, action) => {
      state.addDoorModal = true;
    },
    hideAddDoorModal: (state) => {
      state.addDoorModal = false;
    },
    showEditDoorModal: (state, action) => {
      state.editDoorModal = action.payload;
    },
    hideEditDoorModal: (state) => {
      state.editDoorModal = 0;
    },
    closeAllModals: (state) => {
      state.addDoorModal = false;
      state.shareModal = 0;
      state.logModal = 0;
      state.editDoorModal = 0;
    },
  },
});

export const {
  showLogModal,
  hideLogModal,
  showShareModal,
  hideShareModal,
  showAddDoorModal,
  hideAddDoorModal,
  showEditDoorModal,
  hideEditDoorModal,
  closeAllModals,
} = modalSlice.actions;
export default modalSlice.reducer;
