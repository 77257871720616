import axios from "axios";
import { Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { APIURL } from "../config";
import { useAppDispatch, useAppSelector } from "../hook";
import { doorItem, DoorState, fetchDoors } from "../redux/DoorSlice";
import { showAlert, showConfirm } from "../redux/MessageSlice";
import { closeAllModals, ModalState } from "../redux/ModalSlice";
import Modal from "../utils/modal";

export function EditDoor() {
  const modalState = useAppSelector<ModalState>((state) => state.modal);
  const doorState = useAppSelector<DoorState>((state) => state.door);
  const [currentDoor, setCurrentDoor] = useState<doorItem | null>(null);
  const dispatch = useAppDispatch();

  const handleDelete = (doorId: number | undefined) => {
    dispatch(
      showConfirm({
        title: "Kapı kaydı silinecek\nEmin misiniz?",
        cancelButtonText: "İptal",
        confirmButtonText: "Onayla",
        status: "warning",
        confirmAction: () => {
          axios
            .delete(APIURL + "doors/" + doorId)
            .then((r) => {
              dispatch(fetchDoors());
              dispatch(closeAllModals());
            })
            .catch((e) => {
              console.log("Kapı silme başarısız.");
            });
        },
      })
    );
  };

  function handleSubmit(values: any) {
    axios
      .put(APIURL + "doors/" + currentDoor?.id, values)
      .then((r) => {
        dispatch(fetchDoors());
        dispatch(closeAllModals());
        dispatch(
          showAlert({
            title: values.groupName + "\n" + values.title,
            text: "Başarıyla Düzenlendi.",
            status: "success",
          })
        );
      })
      .catch((e) => {
        console.log("Kayıt başarısız.");
      });
  }

  useEffect(() => {
    var door = doorState.data.find((r) => r.id == modalState.editDoorModal);
    if (door) setCurrentDoor(door);
  }, [modalState.editDoorModal]);

  return (
    <>
      <Modal title="Kapı Düzenle">
        <Formik
          enableReinitialize
          initialValues={{
            title: currentDoor?.title,
            groupName: currentDoor?.groupName,
            serialNumber: currentDoor?.serialNumber,
          }}
          onSubmit={async (values) => {
            handleSubmit(values);
          }}
        >
          <Form action="#">
            <div className="max-w-xl lg:max-w-3xl">
              <div className="mt-1 grid grid-cols-6 gap-6">
                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="FirstName"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Başlık
                  </label>

                  <Field
                    type="text"
                    id="title"
                    name="title"
                    className="mt-1 w-full rounded-md border-gray-200 bg-white text-sm text-gray-700 shadow-sm"
                  />
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="LastName"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Grup Adı
                  </label>

                  <Field
                    type="text"
                    id="groupName"
                    name="groupName"
                    className="mt-1 w-full rounded-md border-gray-200 bg-white text-sm text-gray-700 shadow-sm"
                  />
                </div>

                <div className="col-span-6">
                  <label
                    htmlFor="Email"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Seri Numarası
                  </label>

                  <Field
                    type="text"
                    id="serialNumber"
                    name="serialNumber"
                    className="mt-1 w-full rounded-md border-gray-200 bg-white text-sm text-gray-700 shadow-sm"
                  />
                </div>

                <div className="flex col-span-6 gap-4">
                  <button
                    type="submit"
                    className="w-full  text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                  >
                    Kaydet
                  </button>
                </div>
              </div>
              <p
                onClick={() => {
                  handleDelete(currentDoor?.id);
                }}
                className="text-sm font-bold t mt-8  text-rose-500 cursor-pointer"
              >
                Kapıyı sil
              </p>
            </div>
          </Form>
        </Formik>
      </Modal>
    </>
  );
}
