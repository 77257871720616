import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../hook";
import { AuthState, LoginParameters, loginRequest } from "../redux/AuthSlice";
export default function Login() {
  const [params, setParams] = useState<LoginParameters>({
    email: "",
    password: "",
  });
  const dispatch = useAppDispatch();
  const authState = useAppSelector<AuthState>((state) => state.auth);
  const navigate = useNavigate();

  const handleLogin = (e: any) => {
    e.preventDefault();
    dispatch(loginRequest(params));
  };
  useEffect(() => {
    if (authState.accessToken) {
      navigate("/doors/list");
    }
  }, [authState.accessToken]);

  return (
    <section className="bg-white">
      <div className="lg:grid lg:min-h-screen lg:grid-cols-12">
        <aside className="relative block h-0 lg:order-last lg:col-span-5 lg:h-full xl:col-span-6">
          <img
            alt="Pattern"
            src="/images/back.jpg"
            className="absolute inset-0 h-full w-full object-cover "
          />
        </aside>

        <main
          aria-label="Main"
          className="flex items-center justify-center px-8 py-8 sm:px-12 lg:col-span-7 lg:py-12 lg:px-16 xl:col-span-6"
        >
          <div className="max-w-xl lg:max-w-3xl">
            <img alt="Pattern" src="/images/logo.svg" width={100} />

            <h1 className="mt-6 text-2xl font-bold text-gray-900 sm:text-3xl md:text-4xl">
              WiFi Doors
            </h1>

            <p className="mt-4 leading-relaxed text-gray-500">
              Kapılarınızı Wifi ile açın...
            </p>

            <form onSubmit={(e: any) => handleLogin(e)}>
              <div className="mt-8 grid grid-cols-6 gap-6">
                <div className="col-span-6">
                  <label
                    htmlFor="Email"
                    className="block text-sm font-medium text-gray-700"
                  >
                    E-posta
                  </label>

                  <input
                    onChange={(e) =>
                      setParams({ ...params, email: e.target.value })
                    }
                    type="email"
                    id="Email"
                    name="email"
                    className="mt-1 w-full rounded-md border-gray-200 bg-white text-sm text-gray-700 shadow-sm"
                  />
                </div>

                <div className="col-span-6 ">
                  <label
                    htmlFor="Password"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Şifre
                  </label>

                  <input
                    onChange={(e) =>
                      setParams({ ...params, password: e.target.value })
                    }
                    type="password"
                    id="Password"
                    name="password"
                    className="mt-1 w-full rounded-md border-gray-200 bg-white text-sm text-gray-700 shadow-sm"
                  />
                </div>

                {authState.error && (
                  <h2 className="text-red-700">{authState.error}</h2>
                )}
                <div className="col-span-6 sm:flex sm:items-center sm:gap-4">
                  <button
                    type="submit"
                    className="inline-block shrink-0 rounded-md border border-blue-600 bg-blue-600 px-12 py-3 text-sm font-medium text-white transition hover:bg-transparent hover:text-blue-600 focus:outline-none focus:ring active:text-blue-500"
                  >
                    Giriş Yap
                  </button>

                  <p className="mt-4 text-sm text-gray-500 sm:mt-0">
                    Hesabınız yoksa lütfen &nbsp;
                    <a
                      onClick={() => navigate("/register")}
                      className="cursor-pointer text-gray-700 underline"
                    >
                      Kayıt Olun.
                    </a>
                  </p>
                </div>
              </div>
            </form>
          </div>
        </main>
      </div>
    </section>
  );
}
