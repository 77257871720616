import { useAppDispatch, useAppSelector } from "../hook";
import { MessageState } from "../redux/MessageSlice";
import "./custom.scss";

export const Footer = ({ children }: any) => {
  const dispatch = useAppDispatch();
  const messageState = useAppSelector<MessageState>((state) => state.message);

  return (
    <div className="sticky top-[100vh]">
      <footer>
        <div className="border flex flex-wrap border-gray-200 p-3 px-10 border-b-0 shadow-lg shadow-gray-300 bg-gray-50 font-semibold place-items-center">
          <button></button>
          <div className="flex-grow"></div>
          <p className="mt-4 text-center text-sm text-gray-500 lg:mt-0">
            Copyright &copy; 2023 WifiDoor. All rights reserved.
          </p>
        </div>
      </footer>
    </div>
  );
};
