import axios from "axios";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { APIURL } from "../config";
import Pagination from "../Pagination";
import Modal from "../utils/modal";

export default function Logs({ doorId }: any) {
  const [pageSize, setPageSize] = useState(10);
  const [logs, setLogs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [logCount, setLogCount] = useState(0);
  useEffect(() => {
    axios
      .post(APIURL + "logs/" + doorId, {
        currentPage,
        pageSize,
      })
      .then((res) => {
        setLogs(res.data.rows);
        setLogCount(res.data.count);
      });
  }, [doorId, currentPage, pageSize]);

  return (
    <Modal title="Logs">
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200 text-sm border ">
          <thead className="bg-gray-100">
            <tr>
              <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                Tarih
              </th>
              <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                Kullaınıcı
              </th>
              <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                Cihaz
              </th>
              <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                Erişim Türü
              </th>
            </tr>
          </thead>

          <tbody className="divide-y divide-gray-200">
            {logs &&
              logs.map((log: any, key) => (
                <tr key={key}>
                  <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                    {DateTime.fromISO(log.createdAt)
                      .setLocale("tr")
                      .toFormat("dd LLL yy HH:mm")}
                  </td>
                  <td
                    className="cursor-pointer whitespace-nowrap px-4 py-2 text-gray-700"
                    title={log.user.email}
                  >
                    {log.user.firstName + " " + log.user.lastName}
                  </td>
                  <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                    {log.deviceTitle}
                  </td>
                  <td className="whitespace-nowrap px-4 py-2 text-gray-700 text-center">
                    {log.accessType}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <Pagination
        setPageSize={setPageSize}
        className="pagination-bar"
        currentPage={currentPage}
        totalCount={logCount}
        pageSize={pageSize}
        onPageChange={(page: any) => setCurrentPage(page)}
      />
    </Modal>
  );
}
