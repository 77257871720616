import { useAppDispatch } from "../hook";
import { showAddDoorModal } from "../redux/ModalSlice";

export default function AddDoorButton() {
  const dispatch = useAppDispatch();

  return (
    <>
      <div
        onClick={() => dispatch(showAddDoorModal(true))}
        className="border w-44 mx-auto place-content-center bg-green-100 text-green-500 cursor-pointer flex gap-3 mb-5 p-5 py-3 relative rounded-md border-green-300 shadow-green-100 shadow-md font-semibold"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
        Kapı Ekle
      </div>
    </>
  );
}
