import axios from "axios";
import { useAppDispatch } from "../hook";
import { closeAllModals } from "../redux/ModalSlice";
import { APIURL } from "../config";

interface modalProps {
  title: string;
  children: any | undefined;
}

export default function Modal({ title, children }: modalProps) {
  const dispatch = useAppDispatch();
  const exportReport = () => {
    const startDate = (document.getElementById("dateStart") as HTMLInputElement)
      .value;
    const endDate = (document.getElementById("dateEnd") as HTMLInputElement)
      .value;
    var today = new Date();
    var day = today.getDate();
    var month = today.getMonth() + 1; // Ay 0'dan başlar, bu yüzden 1 ekliyoruz.
    var year = today.getFullYear();
    var fileName = "Report " + day + "." + month + "." + year + ".xlsx";

    axios({
      url: APIURL + "logs/export/" + 8,
      method: "POST",
      data: {
        startDate: startDate,
        endDate: endDate,
      },
      responseType: "blob", // Sunucudan dosyayı binary olarak alır
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName); // İndirme dosyasının adı
      document.body.appendChild(link);
      link.click();
    });
  };

  return (
    <>
      <div className="fixed top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto h-modal md:h-full justify-center items-center flex">
        <div className="relative w-full h-full max-w-md md:max-w-xl md:h-auto">
          <div className="relative bg-white rounded-lg shadow">
            <button
              onClick={() => dispatch(closeAllModals())}
              type="button"
              className="absolute top-5 right-5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
            >
              <svg
                className="w-7 h-7"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <span className="sr-only">Close</span>
            </button>
            <h3 className="text-2xl p-5 font-bold text-gray-600">{title}</h3>
            <div className="flex place-items-center justify-between">
              Başlangıç:
              <input type="date" id="dateStart" />
              Bitiş:
              <input type="date" id="dateEnd" />
              <button
                onClick={exportReport}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full"
              >
                Export
              </button>
            </div>
            <div className="border border-b border-gray-200"></div>
            <div className="w-full p-5  shadow-xl bg-white rounded-lg rounded-t-none">
              {children}
            </div>
          </div>
        </div>
      </div>
      <div
        modal-backdrop=""
        className="bg-gray-900 bg-opacity-50 fixed inset-0 z-40"
      ></div>
    </>
  );
}
