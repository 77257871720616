import axios from "axios";
import { useFormik } from "formik";
import { APIURL } from "../config";
import { useAppDispatch } from "../hook";
import { fetchDoors } from "../redux/DoorSlice";
import { showAlert } from "../redux/MessageSlice";
import { closeAllModals } from "../redux/ModalSlice";
import Modal from "../utils/modal";

export function AddDoor() {
  const dispatch = useAppDispatch();
  const { handleSubmit, handleChange } = useFormik({
    initialValues: {
      title: "",
      groupName: "",
      serialNumber: "",
    },
    onSubmit: (values) => {
      axios
        .post(APIURL + "doors/", values)
        .then((r) => {
          dispatch(closeAllModals());
          dispatch(fetchDoors());
          dispatch(
            showAlert({
              title: values.groupName + "-" + values.title,
              text: "Başarıyla Eklendi",
              status: "success",
            })
          );
        })
        .catch((e) => {
          console.log("Kayıt başarısız.");
        });
    },
  });
  return (
    <Modal title="Yeni Kapı Ekle">
      <form onSubmit={handleSubmit}>
        <div className="max-w-xl lg:max-w-3xl">
          <div className="mt-3 grid grid-cols-6 gap-6">
            <div className="col-span-6 sm:col-span-3">
              <label
                htmlFor="FirstName"
                className="block text-sm font-medium text-gray-700"
              >
                Başlık
              </label>

              <input
                onChange={handleChange}
                type="text"
                id="title"
                name="title"
                className="mt-1 w-full rounded-md border-gray-200 bg-white text-sm text-gray-700 shadow-sm"
              />
            </div>

            <div className="col-span-6 sm:col-span-3">
              <label
                htmlFor="LastName"
                className="block text-sm font-medium text-gray-700"
              >
                Grup Adı
              </label>

              <input
                onChange={handleChange}
                type="text"
                id="groupName"
                name="groupName"
                className="mt-1 w-full rounded-md border-gray-200 bg-white text-sm text-gray-700 shadow-sm"
              />
            </div>

            <div className="col-span-6">
              <label
                htmlFor="Email"
                className="block text-sm font-medium text-gray-700"
              >
                Seri Numarası
              </label>

              <input
                onChange={handleChange}
                type="text"
                id="serialNumber"
                name="serialNumber"
                className="mt-1 w-full rounded-md border-gray-200 bg-white text-sm text-gray-700 shadow-sm"
              />
            </div>

            <div className="col-span-6">
              <p className="text-sm text-gray-500">
                Lütfen eklecek kapının bilgilerini eksiksiz giriniz.
              </p>
            </div>

            <div className="col-span-6 sm:flex sm:items-center sm:gap-4">
              <button
                onChange={handleChange}
                className="inline-block shrink-0 rounded-md border border-blue-600 bg-blue-600 px-12 py-3 text-sm font-medium text-white transition hover:bg-transparent hover:text-blue-600 focus:outline-none focus:ring active:text-blue-500"
              >
                Yeni kapı ekle
              </button>
            </div>
          </div>
        </div>
      </form>
    </Modal>
  );
}
