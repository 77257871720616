import axios from "axios";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { Routers } from "./router/routers";
import { persistor, store } from "./store";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

axios.interceptors.request.use(function (config) {
  const token = store.getState().auth.accessToken;
  if (config && config.headers && token) config.headers.Authorization = token;

  return config;
});

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Routers />
    </PersistGate>
  </Provider>
);
