import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface AlertInterface {
  title: string;
  text: string;
  status?: "success" | "error" | "warning" | "info" | "question";
}
export interface ConfirmInterface {
  title: string;
  confirmButtonText: string | null;
  cancelButtonText: string | null;
  confirmAction: any | null;
  status?: "success" | "error" | "warning" | "info" | "question";
}

export interface MessageState {
  title: string | null;
  text: string | null;
  confirmButtonText: string | null;
  cancelButtonText: string | null;
  confirmAction: any | null;
  status?: "success" | "error" | "warning" | "info" | "question";
}
const initialState: MessageState = {
  title: null,
  text: null,
  confirmButtonText: null,
  cancelButtonText: null,
  confirmAction: null,
  status: "success",
};

export const messageSlice = createSlice({
  name: "message",
  initialState,
  reducers: {
    showAlert: (state, action: PayloadAction<AlertInterface>) => {
      state.title = action.payload.title;
      state.text = action.payload.text;
      state.status = action.payload.status;
    },
    cleanAllMessages: (state) => {
      state.title = null;
      state.text = null;
      state.confirmButtonText = null;
      state.cancelButtonText = null;
      state.confirmAction = null;
    },
    showConfirm: (state, action: PayloadAction<ConfirmInterface>) => {
      state.title = action.payload.title;
      state.confirmButtonText = action.payload.confirmButtonText;
      state.cancelButtonText = action.payload.cancelButtonText;
      state.confirmAction = action.payload.confirmAction;
      state.status = action.payload.status;
    },
  },
});

export const { showAlert, showConfirm, cleanAllMessages } =
  messageSlice.actions;
export default messageSlice.reducer;
