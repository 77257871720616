import React, { useEffect } from "react";
import Swal from "sweetalert2";
import { useAppDispatch, useAppSelector } from "../hook";
import { MessageState, cleanAllMessages } from "../redux/MessageSlice";

export default function PopUpLayout({ children }: any) {
  const dispatch = useAppDispatch();
  const messageState = useAppSelector<MessageState>((state) => state.message);

  useEffect(() => {
    if (messageState.title) {
      if (messageState.confirmAction) {
        Swal.fire({
          title: messageState.title.toString(),
          confirmButtonText: "" + messageState.confirmButtonText,
          showConfirmButton: true,
          cancelButtonText: "" + messageState.cancelButtonText,
          showCancelButton: true,
          icon: messageState.status,
        }).then((result) => {
          if (result.isConfirmed) messageState.confirmAction();
          dispatch(cleanAllMessages());
        });
      } else {
        Swal.fire(
          "" + messageState.title,
          "" + messageState.text,
          messageState?.status
        );
        dispatch(cleanAllMessages());
      }
    }
  }, [messageState]);

  return children;
}
