import axios from "axios";
import { useEffect } from "react";
import AddDoorButton from "../Buttons/addDoorButton";
import { APIURL } from "../config";
import { useAppDispatch, useAppSelector } from "../hook";
import { DoorState, fetchDoors } from "../redux/DoorSlice";
import { showAlert } from "../redux/MessageSlice";
import { ModalState } from "../redux/ModalSlice";
import { AddDoor } from "./addnewDoor";
import { DoorRow } from "./doorrow";
import { EditDoor } from "./editDoor";

export default function List() {
  const dispatch = useAppDispatch();
  const modalState = useAppSelector<ModalState>((state) => state.modal);
  const doorState = useAppSelector<DoorState>((state) => state.door);

  useEffect(() => {
    dispatch(fetchDoors());
  }, []);

  const handleDoorClick = (door: any) => {
    console.log("handle door click");

    axios.get(APIURL + "doors/open/" + door.id).then((res) => {
      console.log("axios inside");

      console.log(res.data);
    });
    dispatch(
      showAlert({
        title: door.groupName + "<hr>\n" + door.title,
        text: "Başarıyla Açıldı!",
        status: "success",
      })
    );
  };

  return (
    <>
      {doorState.isLoading && <div>Loading</div>}
      <div className="max-w-none md:max-w-6xl mx-auto mt-10 px-5 grid grid-cols-1 gap-2 mb-10 md:grid-cols-2 md:gap-5 lg:grid-cols-3 lg:gap-10 ">
        {!doorState.isLoading &&
          doorState.data &&
          doorState.data.map((door: any, index) => (
            <DoorRow
              key={door.id}
              doorClick={() => handleDoorClick(door)}
              door={door}
              keyNo={index}
            />
          ))}
      </div>
      <div className="flex place-content-center mb-10">
        <div className="flex gap-3">
          <AddDoorButton />
        </div>
      </div>
      {doorState.data.length === 0 && (
        <p className="mt-4 text-sm text-amber-500 sm:mt-0 text-center">
          Şuan hesabanızda kayıtlı kapı bulunmamaktadır. Kurumunuzdaki WifiDoors
          kullanılan kapıyı açmak için kurum yetkilisine epostanızı vermeniz
          yeterlidir.
          <br /> Yada WifiDoors cihazı satın alarak kendi kapılarınızı WifiDoors
          ile açabilirsiniz.
        </p>
      )}
      {modalState.addDoorModal && <AddDoor />}
      {modalState.editDoorModal != 0 && <EditDoor />}
    </>
  );
}
