import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { APIURL } from "../config";

export interface userInfo {
  email: string | null;
  firstName: string | null;
  lastName: string | null;
}
export interface AuthState {
  accessToken: string | null;
  shareLink: string | null;
  refreshToken: string | null;
  isLogged: boolean;
  isLoading: boolean;
  error: string | null;
  user: userInfo | null;
  role: string | null;
}

const initialState: AuthState = {
  accessToken: null,
  shareLink: null,
  refreshToken: null,
  isLogged: false,
  isLoading: false,
  error: null,
  role: null,
  user: { email: null, firstName: null, lastName: null },
};

export interface LoginParameters {
  email: string;
  password: string;
}

interface LoginResponse {
  email: string;
  accessToken: string;
  refreshToken: string;
  shareLink: string;
  role: string;
  firstName: string;
  lastName: string;
}

export const loginRequest = createAsyncThunk(
  "loginRequest",
  async (parameters: LoginParameters) => {
    const response = await axios.post<LoginResponse>(
      APIURL + "users/login",
      parameters
    );
    return response.data;
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logOut: (state) => {
      state.isLoading = false;
      state.user = { email: null, firstName: null, lastName: null };
      state.accessToken = null;
      state.refreshToken = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loginRequest.pending, (state, action) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(
      loginRequest.fulfilled,
      (state, action: PayloadAction<LoginResponse>) => {
        state.isLoading = false;
        state.user!.email = action.payload.email;
        state.user!.firstName = action.payload.firstName;
        state.user!.lastName = action.payload.lastName;
        state.role = action.payload.role;
        state.accessToken = action.payload.accessToken;
        state.refreshToken = action.payload.refreshToken;
        state.shareLink = action.payload.shareLink;
        if (action.payload.accessToken && action.payload.refreshToken)
          state.isLogged = true;
        else state.isLogged = false;

        state.error = null;
      }
    );
    builder.addCase(loginRequest.rejected, (state, action) => {
      state.isLoading = false;
      state.user = { email: null, firstName: null, lastName: null };
      state.role = null;
      state.accessToken = null;
      state.refreshToken = null;
      state.shareLink = null;
      state.error = "Login Failed";
    });
  },
});

export const { logOut: logOut } = authSlice.actions;
export default authSlice.reducer;
