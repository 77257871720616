import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Action } from "@remix-run/router";
import axios from "axios";
import { APIURL } from "../config";

export interface DoorState {
  isLoading: boolean;
  data: doorItem[];
  errorMessage: string;
}

const initialState: DoorState = {
  isLoading: false,
  data: [],
  errorMessage: "",
};

export interface doorItem {
  id: number;
  title: string;
  groupName: string;
  serialNumber: string;
  lastConnection?: any;
  isActive: boolean;
  createdAt: Date;
  updatedAt: Date;
}

export const fetchDoors = createAsyncThunk("doorRequest", async () => {
  const response = await axios.get<doorItem[]>(APIURL + "doors/list");
  return response.data;
});

export const doorSlice = createSlice({
  name: "doors",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchDoors.pending, (state, action) => {
      state.isLoading = true;
      state.errorMessage = "";
    });
    builder.addCase(
      fetchDoors.fulfilled,
      (state, action: PayloadAction<doorItem[]>) => {
        state.isLoading = false;
        state.data = action.payload;
        state.errorMessage = "";
      }
    );
    builder.addCase(fetchDoors.rejected, (state, action: any) => {
      state.isLoading = false;
      state.data = [];
      state.errorMessage = action.payload.payload;
    });
  },
});

export default doorSlice.reducer;
