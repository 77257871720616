import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../hook";
import {
  ModalState,
  showEditDoorModal,
  showLogModal,
  showShareModal,
} from "../redux/ModalSlice";
import Logs from "./Logs";
import ShareDoor from "./shareDoor";
export function DoorRow(prop: any) {
  const dispatch = useAppDispatch();
  const modalState = useAppSelector<ModalState>((state) => state.modal);
  const [showButtons, setShowButtons] = useState(false);
  const settingsLogHandle = (e: any) => {
    e.stopPropagation();
    dispatch(showLogModal(prop.door.id));
  };

  const settingsEditDoorHandle = (e: any) => {
    e.stopPropagation();
    dispatch(showEditDoorModal(prop.door.id));
  };

  const settingsShareHandle = (e: any) => {
    e.stopPropagation();
    dispatch(showShareModal(prop.door.id));
  };

  return (
    <>
      {modalState.logModal == prop.door.id && <Logs doorId={prop.door.id} />}

      {modalState.shareModal == prop.door.id && (
        <ShareDoor doorId={prop.door.id} role={prop.door.role} />
      )}
      <div className="relative pb-4 block rounded-xl border shadow-md shadow-gray-100 bg-white mb-5 border-gray-300">
        <div className="flex bg-gray-50  p-4 place-items-start border-b-2 border-gray-300 rounded-xl rounded-bl-none rounded-br-none ">
          <div className="flex flex-col">
            <h3 className="text-sm md:text-base lg:text-lg font-bold text-gray-600 hover:text-red-700 ">
              {prop.door.title}
            </h3>

            <span className="text-sm text-gray-400 flex gap-1">
              {prop.door.groupName}
            </span>
          </div>
          <div className="flex-grow"></div>
          <span className=" rounded-full bg-green-100 px-3 py-1.5 text-xs font-medium text-green-600">
            Aktif
          </span>
        </div>
        <div
          onClick={() => {
            prop.doorClick();
          }}
          className="cursor-pointer text-gray-500  bg-gradient-to-b from-gray-50 via-white"
        >
          <div className="mx-auto w-40 pt-5 flex flex-col place-items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-20 h-20 text-sky-500"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75"
              />
            </svg>
            <span className="font-semibold  text-sky-600">KAPIYI AÇ</span>
          </div>
          {/* {prop.door.role == "owner" && ( */}
          <>
            {showButtons && (
              <div className=" grid grid-cols-3 gap-2 px-4 mt-5   ">
                {prop.door.role == "owner" && (
                  <button
                    className="primary"
                    onClick={(e) => settingsLogHandle(e)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-5 h-5"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M8.25 6.75h12M8.25 12h12m-12 5.25h12M3.75 6.75h.007v.008H3.75V6.75zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zM3.75 12h.007v.008H3.75V12zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm-.375 5.25h.007v.008H3.75v-.008zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"
                      />
                    </svg>
                    LOG
                  </button>
                )}
                {prop.door.role == "user" && <div></div>}
                <button
                  className="primary"
                  onClick={(e) => settingsShareHandle(e)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-5 h-5"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M7.217 10.907a2.25 2.25 0 100 2.186m0-2.186c.18.324.283.696.283 1.093s-.103.77-.283 1.093m0-2.186l9.566-5.314m-9.566 7.5l9.566 5.314m0 0a2.25 2.25 0 103.935 2.186 2.25 2.25 0 00-3.935-2.186zm0-12.814a2.25 2.25 0 103.933-2.185 2.25 2.25 0 00-3.933 2.185z"
                    />
                  </svg>
                  PAYLAŞ
                </button>
                {prop.door.role == "owner" && (
                  <button
                    className="primary"
                    onClick={(e) => settingsEditDoorHandle(e)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-5 h-5"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"
                      />
                    </svg>
                    DÜZENLE
                  </button>
                )}
              </div>
            )}
            <div
              onClick={(e) => {
                e.stopPropagation();
                setShowButtons(!showButtons);
              }}
              className=" flex py-1 mt-5 -mb-8 bg-white place-content-center w-20 mx-auto border-2  border-gray-200 shadow-md rounded-md shadow-gray-200 text-gray-400"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className={`transition-all duration-300 w-6 h-6 ${
                  showButtons ? " rotate-180" : ""
                }`}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M19.5 5.25l-7.5 7.5-7.5-7.5m15 6l-7.5 7.5-7.5-7.5"
                />
              </svg>
            </div>
          </>
          {/* )} */}
        </div>
      </div>
    </>
  );
}
