import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { APIURL } from "./config";
import { useAppDispatch } from "./hook";
import { logOut } from "./redux/AuthSlice";

function AuthCheck(prop: any) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    axios
      .post(APIURL + "users/me")
      .then((result) => {
        setIsLoading(false);
        navigate("/doors/list");
      })
      .catch((result) => {
        dispatch(logOut());
        navigate("/login");
        setIsLoading(false);
      });
  }, []);
  return isLoading ? <div>Page is loading...</div> : prop.children;
}

export default AuthCheck;
