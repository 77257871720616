import axios from "axios";
import { Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { APIURL } from "../config";
import { useAppDispatch, useAppSelector } from "../hook";
import { AuthState } from "../redux/AuthSlice";
import { showAlert, showConfirm } from "../redux/MessageSlice";
import Modal from "../utils/modal";

export default function ShareDoor({ setShareShow, doorId, role }: any) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const userState = useAppSelector<AuthState>((state) => state.auth);
  const [errorText, setErrorText] = useState("");
  const [shareList, setShareList] = useState([]);

  const cleanTokens = () => {
    dispatch(
      showConfirm({
        title:
          "Token bilgileriniz silinecek. \n Oturumunuz kapatılacak \n Emin misiniz?",
        cancelButtonText: "İptal",
        confirmButtonText: "Onayla",
        status: "warning",
        confirmAction: () => {
          axios
            .post(APIURL + "users/cleantokens")
            .then((r) => {
              console.log("Kayıtlı Tokenlar Silindi");
              navigate("/login");
            })
            .catch((e) => {
              console.log("Kayıt silme başarısız.");
            });
        },
      })
    );
  };

  const deleteShare = (shareId: any) => {
    dispatch(
      showConfirm({
        title: "Kapı paylaşımı silinecek\nEmin misiniz?",
        cancelButtonText: "İptal",
        confirmButtonText: "Onayla",
        status: "warning",
        confirmAction: () => {
          axios
            .delete(APIURL + "share/" + shareId)
            .then((r) => {
              console.log("kayıt silindi");
              fetchShareList();
            })
            .catch((e) => {
              console.log("Kayıt silme başarısız.");
            });
        },
      })
    );
  };
  const fetchShareList = () => {
    axios
      .get(APIURL + "share/list/" + doorId)
      .then((r) => {
        setShareList(r.data);
      })
      .catch((e) => {
        console.log("Kayıt başarısız.");
      });
  };
  useEffect(() => {
    fetchShareList();
  }, [doorId]);

  function handleShareSubmit(values: any) {
    if (userState.user?.email == values.email) {
      dispatch(
        showAlert({
          title: "Kullanıcı kendine paylaşım yapamaz!",
          text: "",
          status: "error",
        })
      );
      return;
    }
    axios
      .post(APIURL + "share/", values)
      .then((r) => {
        if (r.data.error) {
          setErrorText(r.data.message);
        }
        fetchShareList();
        dispatch(
          showAlert({
            title: "Başarıyla Eklendi!",
            text: "Eposta: " + values.email,
            status: "success",
          })
        );
      })
      .catch((e) => {
        console.log("Kayıt başarısız.");
      });
  }

  return (
    <>
      <Modal title="Kapı Paylaş">
        {role == "owner" && (
          <>
            <Formik
              initialValues={{
                doorId: "",
                email: "",
                role: "user",
              }}
              onSubmit={async (values) => {
                values.doorId = doorId;
                handleShareSubmit(values);
              }}
            >
              <Form className="space-y-6" action="#">
                <div>
                  <label
                    htmlFor="email"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Kullanıcının e-posta adresini giriniz.
                  </label>
                  <Field
                    type="email"
                    name="email"
                    id="email"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    placeholder="isim@domain.com"
                    required
                  />
                </div>

                <fieldset className="flex flex-wrap gap-3">
                  <legend className="sr-only">Color</legend>

                  <div>
                    <Field
                      type="radio"
                      name="role"
                      value="user"
                      id="user"
                      className="peer hidden"
                    />

                    <label
                      htmlFor="user"
                      className="flex cursor-pointer items-center justify-center rounded-md border border-gray-100 py-2 px-3 text-gray-900 hover:border-gray-200 peer-checked:border-blue-500 peer-checked:bg-blue-500 peer-checked:text-white"
                    >
                      <p className="text-sm font-medium">Kullanıcı</p>
                    </label>
                  </div>

                  <div>
                    <Field
                      type="radio"
                      name="role"
                      value="owner"
                      id="owner"
                      className="peer hidden"
                    />

                    <label
                      htmlFor="owner"
                      className="flex cursor-pointer items-center justify-center rounded-md border border-gray-100 py-2 px-3 text-gray-900 hover:border-gray-200 peer-checked:border-blue-500 peer-checked:bg-blue-500 peer-checked:text-white"
                    >
                      <p className="text-sm font-medium">Yönetici</p>
                    </label>
                  </div>
                </fieldset>

                <button
                  type="submit"
                  className="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                >
                  Kapıyı Paylaş
                </button>
              </Form>
            </Formik>
            <br />
          </>
        )}
        <label
          htmlFor="link"
          className="block mb-2 text-sm font-medium text-gray-900"
        >
          Link Paylaş
        </label>

        <input
          name="link"
          type="text"
          onFocus={(e) => e.target.select()}
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          value={
            "https://app.wifidoors.com/apigateway/doors/open/" +
            doorId +
            "?token=" +
            userState.shareLink?.toString()
          }
        />
        <label
          onClick={() => {
            cleanTokens();
          }}
          className="border mt-2 place-content-center gap-1 rounded-md flex w-full p-2 bg-red-100 cursor-pointer px-3 text-red-700 font-semibold border-red-300"
        >
          Eski Paylaşımları Kaldır
        </label>
        <br />
        {errorText && (
          <div className="p-3 mb-3 border border-red-300 bg-red-100 rounded-lg text-red-700 font-semibold">
            {errorText}
          </div>
        )}
        {role == "owner" && (
          <>
            <div className="font-semibold  border-b pb-2 mb-4 border-gray-200 text-lg">
              Aktif Kullanıcı Listesi
            </div>

            <div className="relative  border  inset-x-0 shadow-xl bg-white  rounded-lg rounded-t-none overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200 text-sm">
                <thead className="bg-gray-100">
                  <tr>
                    <th className="whitespace-nowrap px-2 py-2 text-left font-medium text-gray-900">
                      E-posta
                    </th>
                    <th className="whitespace-nowrap px-2 py-2 text-left font-medium text-gray-900">
                      Kullanıcı
                    </th>
                    <th className="whitespace-nowrap px-2 py-2 text-left font-medium text-gray-900">
                      Yetki
                    </th>
                    <th className="whitespace-nowrap px-2 py-2 text-left font-medium text-gray-900">
                      Sil
                    </th>
                  </tr>
                </thead>

                <tbody className="divide-y divide-gray-200 max-w-max">
                  {shareList &&
                    shareList.map((r: any, key) => (
                      <tr key={key}>
                        <td className="whitespace-nowrap px-2 py-2 text-gray-700 font-semibold">
                          {r.email}
                        </td>
                        <td className="whitespace-nowrap px-2 py-2 text-gray-700">
                          {r.fullName}
                        </td>
                        <td className="whitespace-nowrap px-2 py-2 text-gray-700">
                          {r.role == "owner" ? "Yönetici" : "Kullanıcı"}
                        </td>
                        <td className="whitespace-nowrap px-2 py-2 text-gray-700">
                          {userState.user?.email != r.email && (
                            <button
                              className="border flex place-items-center gap-1 rounded-md bg-red-100 p-1 cursor-pointer px-3 text-red-700 font-semibold border-red-300"
                              onClick={() => {
                                deleteShare(r.id);
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-4 h-4"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                                />
                              </svg>
                              Sil
                            </button>
                          )}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </>
        )}
      </Modal>
    </>
  );
}
