import axios from "axios";
import { Formik, Form, Field } from "formik";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { APIURL } from "../config";

export default function Register() {
  const navigate = useNavigate();
  const [errorText, setErrorText] = useState(String);

  return (
    <Formik
      initialValues={{
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        newPassword: "",
      }}
      onSubmit={async (values) => {
        if (values.password == values.newPassword) {
          axios
            .post(APIURL + "users/register", values)
            .then((r) => {
              console.log("Kayıt yapıldı");
              navigate("/login");
            })
            .catch((e) => {
              setErrorText("E-posta kayıt hatası.");
            });
          console.log("Form alındı.");
        } else {
          setErrorText("Şifreler aynı değil");
        }
      }}
    >
      <Form className="space-y-6" action="#">
        <section className="bg-white">
          <div className="lg:grid lg:min-h-screen lg:grid-cols-12">
            <aside className="relative block h-16 lg:order-last lg:col-span-5 lg:h-full xl:col-span-6">
              <img
                alt="Pattern"
                src="https://images.unsplash.com/photo-1605106702734-205df224ecce?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80"
                className="absolute inset-0 h-full w-full object-cover"
              />
            </aside>
            <main
              aria-label="Main"
              className="flex max-w-xl mx-auto items-center justify-center px-8 py-8 sm:px-12 lg:col-span-7 lg:py-12 lg:px-16 xl:col-span-6"
            >
              <div className="w-full">
                <a className="block text-blue-600" href="/">
                  <span className="sr-only">Home</span>
                  <img alt="Pattern" src="/images/logo.svg" width={100} />
                </a>

                <h1 className="mt-6 text-2xl font-bold text-gray-900 sm:text-3xl md:text-4xl">
                  Kayıt Ol...
                </h1>

                <p className="mt-4 leading-relaxed text-gray-500">
                  Üye olmak için, lütfen aşağıdaki formu eksiksiz doldurunuz.
                </p>
                {errorText && (
                  <div className="p-3 mb-3 border border-red-300 bg-red-100 rounded-lg text-red-700 font-semibold">
                    {errorText}
                  </div>
                )}
                <div className="mt-8 grid grid-cols-6 gap-6">
                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="FirstName"
                      className="block text-sm font-medium text-gray-700"
                    >
                      İsim
                    </label>

                    <Field
                      type="text"
                      id="FirstName"
                      name="firstName"
                      className="mt-1 w-full rounded-md border-gray-200 bg-white text-sm text-gray-700 shadow-sm"
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="LastName"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Soyisim
                    </label>

                    <Field
                      type="text"
                      id="LastName"
                      name="lastName"
                      className="mt-1 w-full rounded-md border-gray-200 bg-white text-sm text-gray-700 shadow-sm"
                    />
                  </div>
                  <div className="col-span-6">
                    <label
                      htmlFor="Email"
                      className="block text-sm font-medium text-gray-700"
                    >
                      E-Posta
                    </label>

                    <Field
                      type="email"
                      id="email"
                      name="email"
                      className="mt-1 w-full rounded-md border-gray-200 bg-white text-sm text-gray-700 shadow-sm"
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="Password"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Şifre
                    </label>

                    <Field
                      type="password"
                      id="Password"
                      name="password"
                      className="mt-1 w-full rounded-md border-gray-200 bg-white text-sm text-gray-700 shadow-sm"
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="newPassword"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Şifre Doğrula
                    </label>

                    <Field
                      type="password"
                      id="newPassword"
                      name="newPassword"
                      className="mt-1 w-full rounded-md border-gray-200 bg-white text-sm text-gray-700 shadow-sm"
                    />
                  </div>

                  <div className="col-span-6 sm:flex sm:items-center sm:gap-4">
                    <button
                      type="submit"
                      className="inline-block shrink-0 rounded-md border border-blue-600 bg-blue-600 px-12 py-3 text-sm font-medium text-white transition hover:bg-transparent hover:text-blue-600 focus:outline-none focus:ring active:text-blue-500"
                    >
                      Hesap Oluştur
                    </button>

                    <p className="mt-4 text-sm text-gray-500 sm:mt-0">
                      Zaten hesabım var?
                      <a
                        href="#"
                        onClick={() => navigate("/login")}
                        className="text-gray-700 underline"
                      >
                        Giriş Yap.
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </main>
          </div>
        </section>
      </Form>
    </Formik>
  );
}
