import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../hook";
import { logOut } from "../redux/AuthSlice";

export default function LogOutButton() {
  const navigate = useNavigate();
  const userState = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const logOut2 = () => {
    dispatch(logOut());
    navigate("/login");
  };

  return (
    <>
      <div
        onClick={() => logOut2()}
        className="flex border cursor-pointer px-3 py-2 bg-sky-100 border-white text-sky-700 rounded-md"
      >
        {userState.user?.firstName + " " + userState.user?.lastName}, ÇIKIŞ
      </div>
    </>
  );
}
